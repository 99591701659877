import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColDef,
  GridLocaleText,
  GridLogicOperator,
  enUS,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { FC, useEffect, useMemo, useState } from "react";
import useLocalizationContext from "../../language/useLocalizationContext";
import { SV_TRANSLATION } from "../../preferences/localization/locales/sv/DataGrid";
import { Scroll } from "../../utils/dataTypes";
import { getFormattedColumns } from "./util/DataGridUtil";

interface DataGridProps extends DataGridPremiumProps {
  columns: GridColDef[];
  rows: any[];
  scroll?: Scroll;
  apiRef?: React.MutableRefObject<GridApiPremium>;
  initialState?: GridInitialStatePremium;
}

const DataGrid: FC<DataGridProps> = ({
  columns,
  rows,
  scroll,
  initialState,
  ...rest
}: {
  rows: any[];
  columns: GridColDef[];
  scroll?: Scroll;
  initialState?: GridInitialStatePremium;
}) => {
  const { language } = useLocalizationContext();
  const [dataGridInitialState] = useState(initialState);

  const formattedColumns = useMemo(() => {
    const fieldOrder = dataGridInitialState?.columns?.orderedFields;

    return fieldOrder != null ? getFormattedColumnsInOrder(fieldOrder, columns) : getFormattedColumns(columns);
  }, [columns, dataGridInitialState]);

  function getFormattedColumnsInOrder(fieldOrder: string[], columns: GridColDef[]) {
    const formattedColumns = getFormattedColumns(columns);

    formattedColumns.sort((a, b) => {
      const indexOfA = fieldOrder.findIndex(field => field === a.field);
      const indexOfB = fieldOrder.findIndex(field => field === b.field);

      if (indexOfA === -1) return 1;
      if (indexOfB === -1) return -1;

      return indexOfA - indexOfB;
    });

    return formattedColumns;
  }

  const localeText = useMemo<Partial<GridLocaleText>>(() => {
    if (language === "sv") return SV_TRANSLATION;

    return enUS.components.MuiDataGrid.defaultProps.localeText as Partial<GridLocaleText>;
  }, [language]);

  //When rows have loaded value, we scoll to position
  useEffect(() => {
    if (scroll === undefined) return;

    if (rows.length > 0) {
      requestAnimationFrame(() => {
        window.scrollTo(scroll.x, scroll.y);
      });
    }
  }, [rows, scroll]);

  return (
    <>
      <DataGridPremium
        initialState={dataGridInitialState}
        filterDebounceMs={300}
        rows={rows}
        columns={formattedColumns}
        slotProps={{
          filterPanel: {
            logicOperators: [GridLogicOperator.And], //BE stödjer inte flera OR i nuläget så detta sätts som default
          },
          toolbar: { printOptions: { disableToolbarButton: true } },
        }}
        disableAggregation //är inte diskuterat hur detta ska fungera så än så länge är det avstängt, den kan aggregate endast inlästa rader
        disableRowGrouping //är inte diskuterat hur detta ska fungera så än så länge är det avstängt, den kan gruppera endast inlästa rader
        localeText={localeText}
        {...rest}
      />
    </>
  );
};

export default DataGrid;
