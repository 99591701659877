import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { fileSliceName } from "./dataTypes";
import { fileAdapter } from "./fileAdapter";
import { addGetPurchaseOrderFilesUploadedToWMSReducers } from "./reducers/getPurchaseOrderFilesUploadedToWMS";
import { addUploadPurchaseOrderFilesToWMSReducers } from "./reducers/uploadPurchaseOrderFilesToWMS";

const slice = createSlice({
  name: fileSliceName,
  initialState: fileAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addGetPurchaseOrderFilesUploadedToWMSReducers(builder);
    addUploadPurchaseOrderFilesToWMSReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
