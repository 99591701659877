import en from "./en";
import sv from "./sv";

export default {
  en,
  sv,
};

export const LANGUAGES = {
  sv_SE: "sv",
  en_US: "en",
} as const;

export const LANGUAGE_LIST = [
  { code: "sv_SE", name: "Svenska" },
  { code: "en_US", name: "English" },
];

export const DEFAULT_LANGUAGE = "sv";
