import { Box } from "@mui/material";
import {
  GridFilterModel,
  GridPaginationModel,
  GridRowId,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useLocation } from "react-router";
import { useImmer, useImmerReducer } from "use-immer";
import { useAppSelector } from "../../app/hooks";
import { makeSelectCustomerOrdersById } from "../../features/customerOrder/customerOrderSelectors";
import {
  CUSTOMER_ORDER_FIELDS,
  CUSTOMER_ORDER_FILTRATION_FIELDS,
  GetCustomerOrdersFilter,
} from "../../features/customerOrder/dataTypes";
import useFetchCustomerOrders from "../../features/customerOrder/hooks/useFetchCustomerOrders";
import useGetCustomerOrders from "../../features/customerOrder/hooks/useGetCustomerOrders";
import { FULFILLED_UPDATE_METHOD } from "../../features/dataTypes";
import { selectCurrentGoodsOwnerId } from "../../features/session/sessionSelectors";
import { BINDER } from "../../features/utils/SqlBinders";
import { FILTER_OPERATOR, ORDER_BY_OPERATOR } from "../../features/utils/SqlOperators";
import { FiltrationField, PaginatedRequestFilter } from "../../features/utils/pagination";
import useCancellablePromise from "../../hooks/useCancellablePromises";
import useTranslate from "../../language/useTranslate";
import useBreadcrumbs from "../../navigation/useBreadcrumbs";
import useHistory from "../../navigation/useHistory";
import { DATE_FORMATS } from "../../utils/DateUtil";
import pages from "../../utils/pages";
import storage from "../../utils/store/storage";
import { TRACK_AND_TRACE_ORDERS_GRID_ID } from "../../utils/store/storeVariables";
import DataGrid from "../DataGrid/DataGrid";
import { DEFAULT_PAGINATION_MODEL } from "../DataGrid/config/DataGridConfig";
import { GridState } from "../DataGrid/dataTypes";
import GridToolbar from "../DataGrid/toolbar/GridToolbar";
import { EXPORT_TYPE } from "../DataGrid/toolbar/GridToolbarUtil";
import { ExportValuesType } from "../DataGrid/toolbar/datatypes";
import { EXPORT_CSV_SETTINGS } from "../DataGrid/toolbar/export/GridToolBarExportUtil";
import {
  FILTER_MODEL_ACTION,
  filterModelReducer,
  getOrderByModel,
  getPaginatedRequestFilter,
  getWhereFilterByModelAndHandleDateEQ,
  useDebouncedScrollListener,
} from "../DataGrid/util/DataGridUtil";
import { useCustomerOrderColumns } from "./CustomerOrderColumns";
import CustomerOrderPackages from "./CustomerOrderPackages";
import { GRID_TYPE, TrackAndTraceTopFilter } from "./TrackAndTracePage";

const GRID_ID = TRACK_AND_TRACE_ORDERS_GRID_ID;
const EXPORT_FILE_NAME = "Track & Trace - Order";
const GRID_HEIGHT_ON_EXPORT = 700;

const reducer = filterModelReducer;
const ACTION = FILTER_MODEL_ACTION;

const CustomerOrdersDataGrid = ({
  topFilter,
  gridState,
}: {
  topFilter?: TrackAndTraceTopFilter;
  gridState?: GridState;
}) => {
  const { push, replaceBreadcrumb } = useHistory();
  const { breadcrumb } = useBreadcrumbs();
  const { pathname } = useLocation();
  const translate = useTranslate();

  const CUSTOMER_ORDER_COLUMNS = useCustomerOrderColumns();

  const serverMode = "server";

  const apiRef = useGridApiRef();

  const [internalGridState, setInternalGridState] = useState(gridState);

  const [{ orderByModel, paginationModel, whereFilterModel, visualFilterModel }, dispatch] = useImmerReducer(reducer, {
    whereFilterModel: gridState?.gridData?.filter?.filterModel ? gridState.gridData.filter.filterModel : { items: [] },
    visualFilterModel: gridState?.gridData?.filter?.filterModel ? gridState.gridData.filter.filterModel : { items: [] },
    orderByModel: gridState?.gridData?.sorting?.sortModel ? gridState.gridData.sorting.sortModel : [],
    paginationModel: gridState?.gridData?.pagination?.paginationModel
      ? (gridState.gridData.pagination.paginationModel as GridPaginationModel)
      : DEFAULT_PAGINATION_MODEL,
  });

  const [expandedRows, setExpandedRows] = useState<GridRowId[]>(
    gridState?.gridData?.detailPanel?.expandedRowIds ? gridState.gridData.detailPanel.expandedRowIds : [],
  );

  const [topFilterDataGrid, setTopFilterDataGrid] = useState<TrackAndTraceTopFilter | undefined>();

  const currentGoodsOwnerId = useAppSelector(selectCurrentGoodsOwnerId);

  const [gridStateListener, setGridStateListener] = useState<GridInitialStatePremium | undefined>({});

  const [autoHeight, setAutoHeight] = useState(true);

  useDebouncedScrollListener(setGridStateListener);

  useEffect(() => {
    if (internalGridState === undefined) return;

    if (
      internalGridState.gridData?.pagination?.paginationModel != paginationModel ||
      internalGridState.gridData.filter?.filterModel != whereFilterModel ||
      internalGridState.gridData.sorting?.sortModel != orderByModel ||
      gridState == null
    ) {
      setInternalGridState(undefined);
      setTopFilterDataGrid(topFilter);
    }
  }, [orderByModel, paginationModel, whereFilterModel, gridState, internalGridState, topFilter]);

  useEffect(() => {
    if (gridState != null) return;

    setTopFilterDataGrid(topFilter);

    dispatch({ type: ACTION.RESET });
  }, [topFilter, gridState, dispatch]);

  const filter = useMemo<GetCustomerOrdersFilter | undefined>(() => {
    if (topFilterDataGrid == null) return undefined;

    const dateFields = [
      CUSTOMER_ORDER_FIELDS.ORDER_DATE,
      CUSTOMER_ORDER_FIELDS.DELIVERY_DATE,
      CUSTOMER_ORDER_FIELDS.LAST_EVENT_TIME_STAMP,
    ];

    const whereGroups = getWhereFilterByModelAndHandleDateEQ(whereFilterModel, dateFields);

    const paginatedRequestFilter = getPaginatedRequestFilter(
      paginationModel,
      getOrderByModel(orderByModel),
      whereGroups,
    );

    if (paginatedRequestFilter.orderByFields?.length === 0) {
      paginatedRequestFilter.orderByFields = [
        { field: CUSTOMER_ORDER_FIELDS.ORDER_DATE, operator: ORDER_BY_OPERATOR.DESC },
        { field: CUSTOMER_ORDER_FIELDS.CLIENTS_ORDER_NUMBER, operator: ORDER_BY_OPERATOR.ASC },
      ];
    }

    paginatedRequestFilter.whereGroups = [
      {
        binder: BINDER.AND,
        fields: [
          {
            field: CUSTOMER_ORDER_FILTRATION_FIELDS.CLIENT,
            operator: FILTER_OPERATOR.EQ,
            value: currentGoodsOwnerId ?? null,
          },
          ...paginatedRequestFilter.whereGroups.flatMap(whereGroup => whereGroup.fields),
        ],
      },
    ];

    const topFilterConverted = convertTopFilter(topFilterDataGrid);

    if (topFilterConverted.length === 0) {
      return paginatedRequestFilter;
    }

    paginatedRequestFilter.whereGroups = [
      {
        binder: BINDER.AND,
        fields: [...topFilterConverted, ...paginatedRequestFilter.whereGroups.flatMap(whereGroup => whereGroup.fields)],
      },
    ];

    return paginatedRequestFilter;
  }, [topFilterDataGrid, whereFilterModel, paginationModel, orderByModel, currentGoodsOwnerId]);

  const { customerOrders, count, isLoading } = useGetCustomerOrders({
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.UPSERT_MANY,
    filter,
  });

  const onFilterChange = useCallback(
    (gridFilterModel: GridFilterModel) => {
      dispatch({
        type: ACTION.CHANGE_WHERE_FILTER_MODEL,
        whereFilterModel: gridFilterModel,
        columns: CUSTOMER_ORDER_COLUMNS,
      });
    },
    [dispatch, CUSTOMER_ORDER_COLUMNS],
  );

  const onSortModelChange = useCallback(
    (gridSortModel: GridSortModel) => {
      dispatch({ type: ACTION.CHANGE_ORDER_BY_MODEL, orderByModel: gridSortModel });
    },
    [dispatch],
  );

  const changePaginationModel = useCallback(
    (changedPaginationModel: GridPaginationModel) => {
      dispatch({ type: ACTION.CHANGE_PAGINATION_MODEL, paginationModel: changedPaginationModel });
    },
    [dispatch],
  );

  function convertTopFilter(filter: TrackAndTraceTopFilter | undefined): FiltrationField[] {
    const filtrationFields: FiltrationField[] = [];

    if (filter == null) return filtrationFields;

    const fromDate = filter.fromDate == null ? undefined : filter.fromDate.toISOString().split("T")[0];
    const toDate = filter.toDate == null ? undefined : filter.toDate.toISOString().split("T")[0];
    const search = filter.search;
    const itemSearch = filter.itemSearch;

    if (fromDate != null) {
      filtrationFields.push({
        value: fromDate,
        operator: FILTER_OPERATOR.GE,
        field: CUSTOMER_ORDER_FILTRATION_FIELDS.ORDER_DATE,
      });
    }

    if (toDate != null) {
      filtrationFields.push({
        value: toDate,
        operator: FILTER_OPERATOR.LE,
        field: CUSTOMER_ORDER_FILTRATION_FIELDS.ORDER_DATE,
      });
    }

    if (search != null && search !== "") {
      filtrationFields.push({
        value: search,
        operator: FILTER_OPERATOR.EQ,
        field: CUSTOMER_ORDER_FILTRATION_FIELDS.SEARCH,
      });
    }

    if (itemSearch != null && itemSearch !== "") {
      filtrationFields.push({
        value: itemSearch,
        operator: FILTER_OPERATOR.EQ,
        field: CUSTOMER_ORDER_FILTRATION_FIELDS.ITEM_SEARCH,
      });
    }

    return filtrationFields;
  }

  const getTrackAndTraceState = useCallback(() => {
    if (gridStateListener == undefined) return;

    const gridData = apiRef.current.exportState();

    gridData.detailPanel = { expandedRowIds: expandedRows };

    return {
      fromDate: topFilterDataGrid?.fromDate ? topFilterDataGrid.fromDate.toISOString().split("T")[0] : undefined,
      toDate: topFilterDataGrid?.toDate ? topFilterDataGrid.toDate.toISOString().split("T")[0] : undefined,
      itemSearch: topFilterDataGrid?.itemSearch,
      search: topFilterDataGrid?.search,
      gridType: GRID_TYPE.ORDER_LEVEL,
      gridState: {
        gridData: gridData,
        scroll: { x: window.scrollX, y: window.scrollY },
        rows: internalGridState?.rows ?? customerOrders,
        count: internalGridState?.count ?? count,
        filterBE: filter ? filter : internalGridState?.filterBE,
      },
    };
  }, [
    internalGridState?.filterBE,
    gridStateListener,
    apiRef,
    count,
    customerOrders,
    expandedRows,
    internalGridState?.count,
    internalGridState?.rows,
    topFilterDataGrid?.fromDate,
    topFilterDataGrid?.itemSearch,
    topFilterDataGrid?.search,
    topFilterDataGrid?.toDate,
    filter,
  ]);

  useEffect(() => {
    replaceBreadcrumb({
      text: breadcrumb?.text ?? translate("TRACK_AND_TRACE"),
      link: pathname,
      data: getTrackAndTraceState(),
    });
  }, [breadcrumb?.link, breadcrumb?.text, getTrackAndTraceState, pathname, replaceBreadcrumb, translate, apiRef]);

  function getSavedGridState(): GridInitialStatePremium | undefined {
    const savedGridState = storage.loadItem(GRID_ID);

    return savedGridState as GridInitialStatePremium | undefined;
  }

  //Handle export
  const [exportValues, setExportValues] = useImmer<ExportValuesType>({
    ids: { allIds: [], uniqueIds: new Set() },
    exportType: undefined,
    promise: undefined,
  });

  const selectCustomerOrders = useMemo(() => makeSelectCustomerOrdersById(exportValues.ids.allIds), [
    exportValues.ids.allIds,
  ]);

  const { addCancellablePromise } = useCancellablePromise(filter);

  const ordersTOExport = useAppSelector(selectCustomerOrders);

  useEffect(() => {
    if (exportValues.promise == null) return;

    addCancellablePromise(exportValues.promise);

    const exportData = async () => {
      const response = await exportValues.promise;

      if (response == null || !response.isSuccessful) return;

      const value = response.value;

      if (value == null) return;

      setExportValues(draft => {
        draft.ids = { allIds: value, uniqueIds: new Set(value) };
      });
    };

    exportData();
  }, [apiRef, setExportValues, exportValues.promise, addCancellablePromise]);

  useEffect(() => {
    if (ordersTOExport.length === 0 || exportValues.exportType == null) return;

    const currentRowIds = apiRef.current.getAllRowIds();

    const exportData = async () => {
      if (exportValues.exportType === EXPORT_TYPE.EXCEL) setAutoHeight(false);

      apiRef.current.setRows(ordersTOExport);

      if (exportValues.exportType === EXPORT_TYPE.CSV) {
        apiRef.current.exportDataAsCsv({ ...EXPORT_CSV_SETTINGS, fileName: EXPORT_FILE_NAME });
      } else if (exportValues.exportType === EXPORT_TYPE.EXCEL) {
        await apiRef.current.exportDataAsExcel({
          columnsStyles: {
            [CUSTOMER_ORDER_FIELDS.ORDER_DATE]: { numFmt: DATE_FORMATS.YYYY_MM_DD_HH_mm },
            [CUSTOMER_ORDER_FIELDS.LAST_EVENT_TIME_STAMP]: { numFmt: DATE_FORMATS.YYYY_MM_DD_HH_mm },
            [CUSTOMER_ORDER_FIELDS.DELIVERY_DATE]: { numFmt: DATE_FORMATS.YYYY_MM_DD },
          },
          fileName: EXPORT_FILE_NAME,
        });
      }

      const idsToDelete = ordersTOExport.map(row => row.id).filter(id => !currentRowIds.includes(id));

      apiRef.current.updateRows(idsToDelete.map(rowId => ({ id: rowId, _action: "delete" })));

      setExportValues(draft => {
        draft.ids = { allIds: [], uniqueIds: new Set() };
        draft.promise = undefined;
      });

      if (exportValues.exportType === EXPORT_TYPE.EXCEL) setAutoHeight(true);
    };

    exportData();
  }, [apiRef, setExportValues, exportValues.exportType, ordersTOExport]);

  return (
    <Box height={autoHeight ? "auto" : GRID_HEIGHT_ON_EXPORT}>
      <DataGrid
        initialState={internalGridState?.gridData ?? getSavedGridState()}
        onColumnOrderChange={() => setGridStateListener({})}
        onPinnedColumnsChange={() => setGridStateListener({})}
        onColumnVisibilityModelChange={() => setGridStateListener({})}
        onColumnWidthChange={() => setGridStateListener({})}
        apiRef={apiRef}
        autoHeight={autoHeight}
        loading={isLoading}
        unstable_headerFilters
        pagination
        paginationMode={serverMode}
        paginationModel={paginationModel}
        pageSizeOptions={[paginationModel.pageSize]}
        onPaginationModelChange={changePaginationModel}
        rows={internalGridState?.rows ?? customerOrders}
        rowCount={internalGridState?.count ?? count}
        columns={CUSTOMER_ORDER_COLUMNS}
        filterMode={serverMode}
        filterModel={visualFilterModel}
        onFilterModelChange={onFilterChange}
        sortingMode={serverMode}
        sortModel={orderByModel}
        onSortModelChange={onSortModelChange}
        scroll={internalGridState?.scroll}
        slots={{
          toolbar: () => CustomToolbar(apiRef, setExportValues, filter ?? internalGridState?.filterBE),
        }}
        detailPanelExpandedRowIds={expandedRows}
        onDetailPanelExpandedRowIdsChange={row => {
          if (row.length === 0) {
            setExpandedRows([]);
          }

          const selectedRow = row[row.length - 1];

          if (selectedRow == null) return;

          setExpandedRows([selectedRow]);
        }}
        getDetailPanelContent={({ row }) => {
          const orders = internalGridState?.rows ?? customerOrders;
          const selectedCustomerOrder = orders.find(customerOrder => customerOrder.id === row.id);

          return <CustomerOrderPackages customerOrderId={row.id} customerOrder={selectedCustomerOrder} />;
        }}
        getDetailPanelHeight={() => "auto"}
        onRowSelectionModelChange={newRowSelectionModel => {
          setGridStateListener({});

          const path = generatePath(pages.CUSTOMER_ORDER_DETAIL.PATH, {
            customerOrder: newRowSelectionModel[0],
          });

          push({
            location: {
              pathname: path,
            },
            breadcrumb: { text: translate("ORDER_OVERVIEW"), link: path },
          });
        }}
      />
    </Box>
  );
};

function CustomToolbar(
  apiRef: React.MutableRefObject<GridApiPremium>,
  setExportValues: React.Dispatch<React.SetStateAction<ExportValuesType>>,
  filter?: PaginatedRequestFilter,
) {
  const fetchCustomerOrders = useFetchCustomerOrders();

  return (
    <GridToolbar
      gridId={GRID_ID}
      apiRef={apiRef}
      setExportValues={setExportValues}
      fetchFunction={fetchCustomerOrders}
      filter={filter}
    />
  );
}

export default CustomerOrdersDataGrid;
