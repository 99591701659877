const en = {
  ACCESS_CARD_TITLE: "Access card",
  ADDRESS: "Address",
  ADJUSTMENT_ORDER_ADJUSTMENT_CODES: "Order / Adjustment Codes",
  ADJUSTMENT_ORDER_ADJUSTMENT_ORDER_TYPE: "Order / Ordertypes adjustment",
  ADMINISTRATE_USERS: "Administrate users",
  ADMINISTRATION: "Administration",
  ALERT_ERROR_TITLE: "Error",
  ALERT_INFO_TITLE: "Info",
  ALERT_SUCCESS_TITLE: "Success",
  ALERT_WARNING_TITLE: "Warning",
  ALREADY_LOGGED_IN: "You are already logged in",
  ADVANCED_AUTHENTICATION: "Advanced authorization",
  ALL: "All",
  AMOUNT_OF_PACKAGES: "Amount of packages",
  APPLICATION_ERROR_LOG: "Application error log",
  ARRIVAL_REGISTRATIONS: "Arrival registrations",
  ARRIVAL_REGISTRATION_MY_PRINTER: "Arrival registration / My Printer",
  AUTHORIZATION: "Authorization",
  APPROVE: "Approve",
  ATTACH: "Attach",
  ATTACHMENTS: "Attachments",
  BACK: "Back",
  BACK_TO_START: "Back to Start",
  BATCH: "Batch",
  BOOKING_SHOW: "Booking - view",
  BOOKING_UPDATE: "Booking - update",
  CA_USER: "Administer users - admin",
  CA_CHANGE_PASSWORD: "User - change password",
  CA_GOODS_OWNER: "Administer goods owner - admin",
  CANCEL: "Cancel",
  CANNOT_SHOW_IMAGE_OF_PACKAGE: "Cannot show image of package",
  CARRIER: "Carrier",
  CARRIER_EVENTS: "Carrier events",
  CARRIER_DETAILS: "Carrier details",
  CARRIER_PRODUCT: "Carrier product",
  CARRIER_STATUS: "Carrier status",
  CASE: "Case",
  CASE_NOT_FOUND: "Case was not found",
  CHANGE_GOODS_OWNER: "Change goods owner",
  CHANGE_PASSWORD: "Change password",
  CHANGE_LOG: "Change log",
  CHOOSE_FILE: "Choose file",
  CHOOSE_GOODS_OWNER: "Choose goods owner",
  CITY: "City",
  CLEAR: "Clear",
  CLICK_LINK_OPEN_AGAIN: "Click the link to open the page again",
  CLIENT_TRANSACTION_CODES: "Client transaction codes",
  CLOSE: "Close",
  CLOSE_THIS_TAB: "Please close this tab",
  CLOSED: "Closed",
  COMFLOW_SYSTEM_SETTINGS: "CF-System settings",
  COMFLOW_USER_SESSIONS: "CF-User sessions",
  COMFLOW_CHANGE_GOODS_OWNER: "Change goods owner",
  COMPLAINTS_INTERNAL: "Complaints - internal",
  COMPLAINTS: "Complaints",
  COMPLAINTS_API: "Complaints API",
  COMPLAINTS_ATTACHMENT_TYPES: "Approved attachment types",
  COMPLAINTS_DAMAGE_CODES: "Damage Codes",
  COMPLAINTS_EMAIL_BUYER: "Email goods owner",
  COMPLAINTS_EMAIL_GREEN_CARGO: "Email PostNord",
  COMPLAINTS_KPI_REPORT: "Complaints / KPI Report",
  COMPLAINTS_KPI_REPORT_LAST_YEAR: "Complaints / KPI Report Last Year",
  COMPLAINTS_END_TEXTS_GOODS_OWNER: "Endtexts by goods owner",
  COMPLAINTS_KPI_TEXTS: "KPI Labeltexts",
  COMPLAINTS_UPDATE_KPI_STATISTICS: "Complaints / Update KPI Statistics",
  COMPLAINTS_MEASURE_CODES: "Action Codes",
  COMPLAINTS_REASON_CATEGORY: "Categories - Reasoncodes",
  COMPLAINTS_REASON_CODES: "Reason Codes",
  COMPLAINTS_REASON_CODES_API: "Reason codes translation",
  COMPLAINTS_RECLAIM_GROUPS: "Complaint groups",
  COMPLAINTS_RECLAIM_LINE_STATUS: "Line status",
  COMPLAINTS_RECLAIM_STATUS: "Complaints Status",
  COMPLAINTS_RECLAIM_STATUS_GOODS_OWNER: "Complaint status by goods owner",
  CONFIRM_SENDING: "Do you want to submit this case?",
  CONFIRMATION_MESSAGE: "Your case is being processed. You can follow your case at",
  CONFIRMATION_TITLE: "Thank you, your case have been submitted!",
  CONSIGNMENT_LIST: "Consignment list",
  CONTACT: "Contact",
  COLUMN_SETTINGS: "Column settings",
  COOKIE_POLICY: "Cookies integrity policy",
  COOKIES: "Cookies",
  CONSTANT_COLUMNS: "Constant columns",
  COUNTRY: "Country",
  CREATE_INTRASTAT_LIS: "Create intrastat LIS",
  CREATE_PROFORMA: "Create proforma",
  CURRENT_PASSWORD: "Current password",
  CUSTOMER_COMPLAINTS: "Customer Complaints",
  CUSTOMER_ID_MISSING: "Customer id missing",
  CUSTOMER_INFO_MISSING: "Customer info missing",
  CUSTOMER_LOG: "Customer log",
  CUSTOMER_NUMBER: "Customer number",
  CUSTOMER_ORDER_REGISTER_NEW: "Customer order - register new",
  CUSTOMER_ORDER_DELETE: "Customer order - delete",
  CUSTOMER_ORDER_NUMBER: "Customer order number",
  CUSTOMER_TYPE_MISSING: "Customer type missing",
  CUSTOMS: "Customs",
  CUSTOMS_LIST: "Customs list",
  CUSTOMS_FILE_IMPORT: "Customs file - import",
  DATA_MISSING: "Data missing",
  DATE: "Date",
  DELIVERED: "Delivered",
  DELIVERY_ORDERS: "Delivery orders",
  DELIVERY_ORDERS_ARCHIVE: "Delivery orders - archived",
  DELIVERY_CREATE_RETURNS: "Return - register new",
  DELIVERY_LIST_RETURNS: "Returns - list",
  DELIVERY_RETURNS_INTERNAL: "Returns - list internal",
  DESCRIPTION: "Description",
  DEVIATION_ACTION_CODES: "Deviation / Action codes",
  DEVIATION_EMAIL_GOODS_OWNER: "Deviation / Email Goods Owner",
  DEVIATION_GROUPS: "Deviation / Groups",
  DEVIATION_POST_NORD_EMAIL: "Deviation / Email PostNord",
  DELIVERY_ORDER_REPORTS: "Delivery order / Reports",
  DEVIATION_REASON_CATEGORIES: "Deviation / Reason categories",
  DEVIATION_REASON_CODES: "Deviation / Reason codes",
  DEVIATION_STATUS: "Deviation / Status",
  DEVIATIONS: "Deviations",
  DEVIATIONS_INTERNAL: "Deviations - internal",
  DEVIATIONS_OUTBOUND: "Outbound deviations",
  DROP_FILES_HERE: "Drop files here",
  EMAIL: "E-mail",
  EMAIL_MISSING: "E-mail missing",
  EMAIL_OTS_LIB: "Email (OTS Lib)",
  EMAIL_TEMPLATES: "Email templates",
  EMAIL_TEST: "Email test to system test address",
  EMBEDDED_PATH_INCORRECT: "Embedded path is incorrect",
  ETA_FROM_CARRIER: "ETA from carrier",
  EVENT: "Event",
  EVENTS: "Events",
  EXPIRY_DATE: "Expiry date",
  EXPIRED_PASSWORD_TITLE: "Your password has expired and needs to be changed",
  FALSE: "No",
  FILE_NAME: "File name",
  FILES_OTS_LIB: "Files (OTS Lib)",
  FIELD_AUDIT_TRAIL_INQUIRY: "Field Audit Trail Inquiry",
  FIELD_AUDIT_REASON_CODES: "Field Audit Reason Codes",
  FIELD_AUDIT_TRAIL_CONTROL: "Field Audit Trail Control",
  FILES_AND_ATTACHMENTS: "Files / Attachments",
  FIRST_NAME: "First name",
  FIRST_NAME_MISSING: "First name missing",
  FOOTER_COMPANY_NAME: "PostNord TPL AB",
  FORGOT_PASSWORD_TEXT: "Type in your email address and we will send you a link to reset your password.",
  FORGOT_PASSWORD: "Forgot your password",
  FROM_DATE: "From date",
  FROM_ORDER_DATE: "From order date",
  FUNCTION_GROUPS: "Function groups",
  FUNCTION_AUTHORIZATION: "Function authorization",
  GDPR_CONFIRMATION_MESSAGE: "I have read and I approve the contents of the message.",
  GENERAL: "General",
  GOODS_OWNER: "Goods owner",
  GOODS_OWNER_CHANGE_WARNING:
    "You are about to leave this page. All changes will be lost. Do you want to change goods owner?",
  GOODS_OWNER_REGION: "Goods owner by region",
  HEADER_TITLE: "TPL KT 2023",
  HOME: "Home",
  IF_PAGE_NOT_OPENED_POP_UP_ERROR_EXPLAINED:
    "If the page didn't open you might have a pop up blocker in you web browser preventing new tabs to be opened.",
  INTERNET_CONNECTION_ERROR_DESCRIPTION: "The contact with the server was lost, check your connection!",
  INBOUND_SHIPMENTS: "Inbound shipments",
  INTEGRITY_POLICY: "Integrity policy",
  INTERNAL_COMPLAINTS: "Complaints - internal",
  INTERNAL_STATUSES: "Internal status per shipment type",
  IMPORT: "Import",
  IMPORT_DATE: "Import date",
  IMPORT_HISTORY: "Import history",
  IMPORT_ORDER_BH: "Import order BH",
  IMPORTED_BY: "Imported by",
  ITEM: "Item",
  ITEM_NUMBER_SHORT: "Item no",
  ITEMS: "Items",
  ITEMS_PRELIMINARY: "Items - preliminary",
  ITEMS_REQUEST: "Items request",
  ITEM_BATCH: "Item batch",
  ITEM_REGISTER_NEW: "Item - register new",
  INBOUND: "Inbound",
  INBOUND_ORDER_SHORTAGE_ITEMS: "Order - shortage items",
  INBOUND_BASIC_DATA: "Inbound / Basic data",
  INBOUND_GENERATE_CALENDAR: "Inbound / Generate calendar",
  INSTALLED_LICENSE_OBJECTS: "Installed License Objects",
  IS_EMPTY: "Is empty",
  IS_NOT_EMPTY: "Is not empty",
  JOB_SCHEDULER: "Job scheduler",
  JOB_SCHEDULER_OTS_LIB: "Job scheduler (OTS Lib)",
  KEY_MANAGEMENT: "Key management",
  KHG_PARAMETERS: "KHG parameters",
  LAST_EVENT: "Last event",
  LAST_EVENT_TIME: "Last event time",
  LEAD_TIME_REPORTS: "Lead time reports",
  LICENSE_FILES: "License Files",
  LICENSE_ACCESS: "License Access",
  LICENSE_ALLOCATION_FOR_USER_GROUP: "License Allocation for User Group",
  LICENSED_USERS: "Licensed Users",
  LICENSED_USER_FUNCTIONS: "Licensed User Functions",
  LICENSED_USER_WORKSTATIONS: "Licensed User Workstations",
  LICENSE_WORKSTATIONS: "License Workstations",
  LICENSES: "Licenses",
  LINE: "Line",
  LINE_NBR: "Line nbr",
  LINK: "Link",
  LOADING_DATE: "Loading date",
  LOCALES: "Locales",
  LOCATION: "Location",
  LOG_OUT_BEFORE_RESET_PASSWORD: "You need to log out before resetting you password",
  LOG_OUT_IN_PROGRESS: "You're being logged out...",
  LOGIN: "Login",
  LOGGING: "Logging",
  LOGGER_OTS_LIB: "Logger (OTS Lib)",
  LOGOUT: "Logout",
  MENU: "Menu",
  MANAGE_REPORTS: "Manage reports",
  MANAGE_COMPLAINTS: "Manage complaints",
  MENU_SERVICES: "Order",
  MENU_REPORT_ERROR: "Error report",
  MENU_ACCESS_CARD: "Access card",
  MENU_INFORMATION: "Information",
  MESSAGES_AND_TEMPLATES: "Messages & templates",
  MONITOR_ORDER_ITEMS: "Monitor order / item",
  MY_ACCOUNT: "My account",
  MY_ACCOUNT_SUBHEADER: "Handle your information and safety settings",
  MY_QLIK_SENSE_REPORTS: "QlikSense / My reports",
  MY_REPORTS: "My reports",
  MY_REPORTS_CREATE: "Create report records",
  MY_REPORTS_EDIT: "My reports - add, update",
  MY_REPORTS_LINK: "My reports  - download report",
  MY_REPORTS_READ: "My reports - view",
  NEW_PASSWORD: "New password",
  NEW_ARRIVAL_REGISTRATION: "New arrival registration",
  NO: "No",
  NO_ATTACHMENTS: "No attachments",
  NO_CONNECTION: "No connection",
  NO_FORM_DATA: "No form data found",
  NO_RESULT_FOUND: "No result found",
  NO_ROWS: "No rows",
  NOTIFICATIONS: "Notifications",
  NUMBER_SERIES_OTS: "Number series (OTS Lib)",
  OF: "of",
  OPEN: "Open",
  OPEN_AGAIN: "Open again",
  OPEN_FILE_MANAGER: "Open file manager",
  OPERATIONS: "Operations",
  ORDERED: "Ordered",
  ORDER_CLIENT: "Order client",
  ORDER_DATE: "Order date",
  ORDER_LEVEL: "Order level",
  ORDER_LINES: "Order lines",
  ORDER_NUMBER: "Order number",
  ORDER_OVERVIEW: "Order overview",
  ORDER_REFERENCE: "Order reference",
  ORDER_STATUS_10: "Preliminiary",
  ORDER_STATUS_20: "Ordered",
  ORDER_STATUS_30: "Processed",
  ORDER_STATUS_80: "In process",
  ORDER_STATUS_90: "Completed",
  ORDER_STATUS_99: "Cancelled",
  ORDER_TIME: "Order time",
  ORDER_TYPE: "Order type",
  OUTBOUND: "Outbound",
  OUTBOUND_CREATE_NEW_ORDER: "Create new outbound order",
  OUTBOUND_TRACK_AND_TRACE: "Outbound",
  PACKAGE: "Package",
  PACKAGE_LEVEL: "Package level",
  PACKAGE_NUMBER: "Package number",
  PACKAGE_OVERVIEW: "Package overview",
  PACKAGES: "Packages",
  PAGE_NOT_FOUND_DESCRIPTION:
    "The page could not be found, contact the support or the administrator at recurrent problems",
  PAGE_NOT_FOUND: "The page could not be found!",
  PASSWORD: "Password",
  PASSWORD_CHANGED: "Your password has been changed, please log in again.",
  PASSWORDS_DOESNT_MATCH: "Password and repeat password doesn't match",
  PASSWORD_EXPIRED: "Password expired",
  PASSWORD_HAS_TO_HAVE: "Password has to have:",
  PASSWORD_MISSING: "Password missing",
  PASSWORD_POLICIES: "Password policies",
  PASSWORD_REPEAT_MISSING: "Password repeat missing",
  PATCH_LEVEL: "Patch level",
  PERSONAL_DATA: "Personal data",
  PHONE_NO: "Phone no",
  PHONE_NUMBER_MISSING: "Phone number missing",
  PHONE_SHORT: "Tel",
  PLANNING_LIST: "Planning list",
  PLANNING_LIST_EXTERNAL: "Planning list external",
  PREVIOUS_PASSWORD_MISSING: "Previous password missing",
  PROFILE_INFORMATION_UPDATED: "Your information has been updated",
  PURCHASE_ORDER_CREATE: "Create order",
  PURCHASE_ORDER_IMPORT: "Purchase order - import",
  PURCHASE_ORDER_IMPORT_FILE_DESCRIPTION: "Here you can import a purchase order by choosing a file to import.",
  PURCHASE_ORDER_REQUEST: "Purchase order request",
  PURCHASE_ORDER_REGISTER_NEW: "Purchase order - register new",
  PURCHASE_ORDERS_PRELIMINARY: "Purchase orders - preliminary",
  QLIK_SENSE: "Qlik Sense",
  QUALITY_REPORTS: "Quality reports",
  QLIK_SENSE_USER: "Qlik Sense User",
  QUICK_ACCESS: "Quick access",
  QUANTITY_FROM_BATCH: "Quantity from batch",
  READ_MORE: "Read more",
  REALM: "Realm",
  RECIPIENT: "Recipient",
  RECIPIENT_ADRESS: "Recipient adress",
  RECIPIENT_DETAIL: "Recipient detail",
  RECIPIENT_EMAIL: "Recipient email",
  RECIPIENT_NAME: "Recipient name",
  RECIPIENT_TELEPHONE_NUMBER: "Recipient telephone number",
  RECLAMATIONS: "Reclamations",
  RECEIVER: "Receiver",
  REDIRECT_USER: "User - Redirect",
  REGION: "Region",
  REGIONS: "Regions",
  RELOAD: "Reload",
  REPEAT_PASSWORD: "Repeat password",
  REPORT_ERROR_TITLE: "Report error",
  REPORTS: "Reports",
  RESET_PASSWORD_TITLE: "Set your new password",
  RESET_PASSWORD: "Reset password",
  RESET: "Reset",
  RESET_LINK_IS_SENT: "A link for resetting your password has been reset to your e-mail",
  RESERVATIONS_LIST: "Reservations list",
  RETURN_CARRIER_SERVICES: "Return / Carrier services",
  RETURN_HANDLING_LOAD_CARRIER: "Return handling - load carrier",
  RETURN_MAIN_INFO_GOODS_OWNER: "Return / Settings by goods owner",
  RETURN_PACKAGE_TYPES: "Return / Package types",
  RETURN_PRINTERS: "Return / Printers",
  RETURN_RECEIVER_ADDRESS: "Return / Receiving addresses",
  RETURN_STATUS: "Return / Template Return status",
  RETURN_TYPES: "Return / Templates types of returnables",
  RESERVED: "Reserved",
  REQUESTED_DELIVERY_DATE: "Requested delivery date",
  ROWS: "Rows",
  SAVE: "Save",
  SAVE_NEW_PASSWORD: "Save new password",
  SAVE_VIEW: "SAVE VIEW",
  SEARCH: "Search",
  SEARCH_FOR_CASE: "Search for case",
  SEND: "Send",
  SERVER_ERROR: "Server error",
  SERVER_ERROR_DESCRIPTION: "The server could not be reached - contact support if the problem persists",
  SERVER_ERROR_UNEXPECTED_DESCRIPTION:
    "An unexpected error occurred. Please try again and contact support if the problem persists",
  SERVER_ERROR_DESCRIPTION_REQUIRE_LOGIN: "The server could not be reached - please login again",
  SERVER_COULDNT_PROCESS:
    "The server was unable to return any data, please contact support and describe where the error occurred",
  SERVICE_TYPE: "Service type",
  SERVICE_TYPE_NOT_FOUND: "No service type was found",
  SERVICES_TITLE: "Order",
  SESSION_EXPIRED: "Your session has expired. You need to sign in again to continue using the application",
  SETTINGS: "Settings",
  SETTINGS_OTS_LIB: "Settings (OTS Lib)",
  SHOW_PAGE_SIZE: "Show items per page",
  SHOW_RESULT_ON: "Show result on",
  SHIPMENT: "Shipment",
  SHIPMENT_LEVEL: "Shipment level",
  SHIPMENT_NUMBER: "Shipment number",
  SHIPMENT_OVERVIEW: "Shipment overview",
  SITE_TITLE: "TPL KT 2023",
  SMS: "SMS",
  SMS_TEMPLATES: "SMS templates",
  STADIUM_PRICE_LABELING: "Price labeling - Stadium",
  STATISTICS: "Statistics",
  STATUS: "Status",
  STATUS_COMMENT: "Status comment",
  STATUS_CODE: "Status code",
  STATUS_CODE_COLOR: "Event colors",
  STATUS_TRANSLATION: "Status translations",
  SYSTEM_ADMINISTRATION: "System administration",
  SYSTEM_CONTROL: "System control",
  SYSTEM_STATISTICS: "Statistics",
  SYSTEM_TOOLS: "System tools",
  SUB_NAV_MY_ACCOUNT: "My account",
  SUB_NAV_MY_CASES: "My cases",
  SUB_NAV_MY_CASES_INFO: "Details",
  SUB_NAV_MY_CASES_FORM_DATA: "Form data",
  SUB_NAV_MY_CASES_ATTACHMENTS: "Attachments",
  SUB_NAV_MY_PERSONAL_DATA: "Personal data",
  SUB_NAV_MY_PASSWORD: "Password",
  SUPPLEMENT: "Supplement",
  SUPPORT: "Support",
  SURNAME: "Surname",
  SURNAME_MISSING: "Surname missing",
  TIME: "Time",
  TO_DATE: "To date",
  TO_ORDER_DATE: "To order date",
  TOKEN_MISSING: "Token missing",
  TRACK_AND_TRACE: "Track & Trace",
  TRACK_AND_TRACE_DESCRIPTION: "Track your customer orders",
  TRACK_AND_TRACE_PAGE_SEARCH: "Search by order no, shipment no or package no",
  TRACK_AND_TRACE_STATISTICS: "Track & Trace / Statistics",
  INVENTORY_TRANSACTIONS: "Inventory transactions",
  TRACK_PACKAGE_WITH_CARRIER: "Track package with carrier",
  TRANSACTIONS: "Transactions",
  TRUE: "Yes",
  TRYING_TO_RECONNECT: "Trying to connect to the server...",
  UNABLE_TO_LOG_OUT: "Unfortunately we were unable to log you out, please try again",
  UNLOADING_REPORTS_SHOW: "Unloading reports - view",
  UPLOAD_DOCUMENT: "Attach document",
  UPLOAD_SUCCESSFUL: "Upload successful",
  USER: "User",
  USER_ACCESS_MESSAGES: "User Access Messages",
  USER_ACCESS_MESSAGES_EXCEPTIONS: "User Access Messages - Exceptions",
  USER_ACCESS_MESSAGES_READ_LOG: "User Access Messages - Read Log",
  USER_IS_NOT_LOGGED_IN: "User is not logged in",
  USER_MESSAGE_NOT_ACCEPTED: "The term must be accepted to use this service",
  USER_MESSAGES: "User messages",
  USERNAME: "Username",
  USERNAME_MISSING: "Username missing",
  USER_GROUPS: "User Groups",
  USER_LOGIN_HISTORY: "User login history",
  USER_ROLES: "User Roles",
  USER_SESSIONS: "User sessions",
  USER_TASK_LOG: "User task log",
  USERS: "Users",
  USERS_CONTROL_TOWER: "Users Control Tower",
  USERS_GROUPS_AND_ROLES: "Users, groups and roles",
  VERSION: "Version",
  VIEW_PACKAGE: "View package",
  VIRTUAL_SESSIONS: "Virtual sessions",
  VOLUME: "Volume",
  VOLUME_STATISTICS: "Volume statistics",
  WAREHOUSE: "Warehouse",
  WEB_AND_REST_SERVICES: "Webb & REST Services",
  WEIGHT: "Weight",
  WELCOME_TEXT: "",
  WELCOME_TITLE: "Welcome to the Control Tower",
  WEEK_LETTER_SUPPORT: "Administrate notifications",
  WORK_WITH_PROFORMA: "Work with proforma",
  WRONG_USERNAME_OR_PASSWORD: "Wrong username or password",
  YES: "Yes",
  YOUR_CASES: "your cases",
  YOUR_LINK_OPENED_IN_NEW_TAB: "Din länk har öppnats i en ny flik i webbläsaren",
  ZETES_IMAGE_LINK: "Zetes-link",
  ZIP_CODE: "Zip code",
};

export default en;
