import { CircularProgress } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useMemo } from "react";
import { generatePath } from "react-router";
import {
  CUSTOMER_ORDER_PACKAGE_FILTRATION_FIELDS,
  GetCustomerOrderPackagesFilter,
} from "../../features/customerOrderPackage/dataTypes";
import useGetCustomerOrderPackages from "../../features/customerOrderPackage/hooks/useGetCustomerOrderPackages";
import { FULFILLED_UPDATE_METHOD } from "../../features/dataTypes";
import { Shipment } from "../../features/shipment/dataTypes";
import { BINDER } from "../../features/utils/SqlBinders";
import { SQL_OPERATOR } from "../../features/utils/SqlOperators";
import useTranslate from "../../language/useTranslate";
import useHistory from "../../navigation/useHistory";
import { DATE_FORMATS } from "../../utils/DateUtil";
import pages from "../../utils/pages";
import {
  DEFAULT_TABLE_BODY_ROW_STYLE,
  DEFAULT_TABLE_CONTAINER_STYLE,
  DEFAULT_TABLE_HEADER_ROW_STYLE,
} from "../DataGrid/DetailPanel/DetailPanelGridUtil";

const ShipmentDataGridDetailPanel = ({ shipment }: { shipment: Shipment }) => {
  const history = useHistory();
  const translate = useTranslate();

  const filter = useMemo<GetCustomerOrderPackagesFilter>(() => {
    const shipmentIds = shipment.id.split("¤");

    const paginatedRequestFilter: GetCustomerOrderPackagesFilter = {
      paginationOptions: {
        all: true,
        amount: 0,
        from: 0,
      },
      orderByFields: [],
      whereGroups: [
        {
          binder: BINDER.AND,
          fields: [
            {
              field: CUSTOMER_ORDER_PACKAGE_FILTRATION_FIELDS.CLIENT,
              operator: SQL_OPERATOR.EQ,
              value: shipmentIds[0] ?? "",
            },
            {
              field: CUSTOMER_ORDER_PACKAGE_FILTRATION_FIELDS.SHIPMENT_NUMBER,
              operator: SQL_OPERATOR.EQ,
              value: shipmentIds[1] ?? "",
            },
          ],
        },
      ],
    };

    return paginatedRequestFilter;
  }, [shipment]);

  const { customerOrderPackages, isLoading } = useGetCustomerOrderPackages({
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.UPSERT_MANY,
    filter,
  });

  if (isLoading) return <CircularProgress />;
  return (
    <>
      <TableContainer component={Paper} elevation={0} sx={DEFAULT_TABLE_CONTAINER_STYLE}>
        <TableRow>
          <TableCell sx={{ borderBottom: "none" }}>
            <Typography variant="h6">
              {translate("SHIPMENT_NUMBER")} #{shipment.shipmentNumber}
            </Typography>
          </TableCell>
        </TableRow>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow sx={DEFAULT_TABLE_HEADER_ROW_STYLE}>
              <TableCell>{translate("PACKAGE_NUMBER")}</TableCell>
              <TableCell>{translate("LAST_EVENT")}</TableCell>
              <TableCell>{translate("LAST_EVENT_TIME")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerOrderPackages.length === 0 ? (
              <TableRow sx={DEFAULT_TABLE_BODY_ROW_STYLE}>
                <TableCell component="th" scope="row" colSpan={3} sx={{ textAlign: "center" }}>
                  {translate("NO_ROWS")}
                </TableCell>
              </TableRow>
            ) : (
              customerOrderPackages.map(row => (
                <TableRow
                  key={row.id}
                  sx={DEFAULT_TABLE_BODY_ROW_STYLE}
                  hover={true}
                  onClick={() => {
                    const path = generatePath(pages.CUSTOMER_ORDER_PACKAGE_DETAIL.PATH, {
                      customerOrderPackage: row.id,
                    });

                    history.push({
                      location: {
                        pathname: path,
                      },
                      breadcrumb: { text: translate("PACKAGE_OVERVIEW"), link: path },
                    });
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.sscc}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.lastEvent}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.lastEventTimeStamp ? dayjs(row.lastEventTimeStamp).format(DATE_FORMATS.YYYY_MM_DD) : ""}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ShipmentDataGridDetailPanel;
