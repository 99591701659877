import { LicenseInfo } from "@mui/x-data-grid-premium";
import { applyPolyfills, defineCustomElements } from "@postnord/web-components/loader";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";

Sentry.init({
  dsn: "https://0648b99c973b44a8a362c22dedaa1fc8@o571717.ingest.sentry.io/5720257",
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,

  tracesSampleRate: 1.0,
});

LicenseInfo.setLicenseKey(
  "636be66b979a512d1d5882eb023450e8Tz03ODY2MixFPTE3MzE1MTgyNTcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",
);

ReactDOM.render(<App />, document.getElementById("root"));

applyPolyfills().then(() => {
  defineCustomElements();
});
