import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { PaginatedRequestFilter } from "../../../features/utils/pagination";
import { EXPORT_TYPE } from "./GridToolbarUtil";
import { ExportValuesType, FetchFunctionType } from "./datatypes";
import CustomGridToolbarExport from "./export/GridToolbarExport";
import GridToolbarSaveView from "./saveView/GridToolbarSaveView";

const DEFAULT_TOOLBAR_STYLES = {
  textTransform: "uppercase",
};

const GridToolbar = ({
  gridId,
  apiRef,
  setExportValues,
  fetchFunction,
  filter,
}: {
  gridId: string;
  apiRef: React.MutableRefObject<GridApiPremium>;
  setExportValues: React.Dispatch<React.SetStateAction<ExportValuesType>>;
  fetchFunction: FetchFunctionType;
  filter?: PaginatedRequestFilter;
}) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton sx={DEFAULT_TOOLBAR_STYLES} />
      <GridToolbarFilterButton sx={DEFAULT_TOOLBAR_STYLES} />
      <GridToolbarExportContainer sx={DEFAULT_TOOLBAR_STYLES}>
        <CustomGridToolbarExport
          apiRef={apiRef}
          setExportValues={setExportValues}
          filter={filter}
          fetchFunction={fetchFunction}
          type={EXPORT_TYPE.CSV}
        />
        <CustomGridToolbarExport
          apiRef={apiRef}
          setExportValues={setExportValues}
          filter={filter}
          fetchFunction={fetchFunction}
          type={EXPORT_TYPE.EXCEL}
        />
      </GridToolbarExportContainer>
      <GridToolbarSaveView gridOnPage={gridId} apiRef={apiRef} />
    </GridToolbarContainer>
  );
};

export default GridToolbar;
