import { CircularProgress } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useMemo } from "react";
import { generatePath } from "react-router";
import { CustomerOrder } from "../../features/customerOrder/dataTypes";
import {
  CUSTOMER_ORDER_PACKAGE_FILTRATION_FIELDS,
  GetCustomerOrderPackagesFilter,
} from "../../features/customerOrderPackage/dataTypes";
import useGetCustomerOrderPackages from "../../features/customerOrderPackage/hooks/useGetCustomerOrderPackages";
import { FULFILLED_UPDATE_METHOD } from "../../features/dataTypes";
import { BINDER } from "../../features/utils/SqlBinders";
import { SQL_OPERATOR } from "../../features/utils/SqlOperators";
import useTranslate from "../../language/useTranslate";
import useHistory from "../../navigation/useHistory";
import { DATE_FORMATS } from "../../utils/DateUtil";
import pages from "../../utils/pages";

const TABLE_ROW_STYLE = {
  border: "none",
  height: "1rem",
  "& .MuiTableCell-root": {
    border: "none",
  },
};

const CustomerOrderPackages = ({
  customerOrderId,
  customerOrder,
}: {
  customerOrderId: string;
  customerOrder?: CustomerOrder;
}) => {
  const history = useHistory();
  const translate = useTranslate();

  const filter = useMemo<GetCustomerOrderPackagesFilter>(() => {
    const customerOrderIds = customerOrderId.split("¤");

    const paginatedRequestFilter: GetCustomerOrderPackagesFilter = {
      paginationOptions: {
        all: true,
        amount: 0,
        from: 0,
      },
      orderByFields: [],
      whereGroups: [
        {
          binder: BINDER.AND,
          fields: [
            {
              field: CUSTOMER_ORDER_PACKAGE_FILTRATION_FIELDS.CLIENT,
              operator: SQL_OPERATOR.EQ,
              value: customerOrderIds[0] ?? "",
            },
            {
              field: CUSTOMER_ORDER_PACKAGE_FILTRATION_FIELDS.ORDER_NUMBER,
              operator: SQL_OPERATOR.EQ,
              value: customerOrderIds[1] ?? "",
            },
          ],
        },
      ],
    };

    if (customerOrder?.shipmentNumber != null && customerOrder.shipmentNumber !== "") {
      paginatedRequestFilter.whereGroups = [
        {
          binder: BINDER.AND,
          fields: [
            {
              field: CUSTOMER_ORDER_PACKAGE_FILTRATION_FIELDS.SHIPMENT_NUMBER,
              operator: SQL_OPERATOR.EQ,
              value: customerOrder.shipmentNumber,
            },
            ...paginatedRequestFilter.whereGroups.flatMap(whereGroup => whereGroup.fields),
          ],
        },
      ];
    }

    return paginatedRequestFilter;
  }, [customerOrderId, customerOrder]);

  const { customerOrderPackages, isLoading } = useGetCustomerOrderPackages({
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.UPSERT_MANY,
    filter,
  });

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            margin: "1.5rem 4.5rem",
            border: "1px solid",
            borderColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: "16px",
            width: "40%",
          }}
        >
          <TableRow>
            <TableCell sx={{ borderBottom: "none" }}>
              <Typography variant="h6">Order #{customerOrder?.clientsOrderNumber}</Typography>
            </TableCell>
          </TableRow>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  borderBottom: "1px solid",
                  borderColor: "rgba(0, 0, 0, 0.1)",
                  "& .MuiTableCell-root": {
                    border: "none",
                  },
                }}
              >
                <TableCell>{translate("PACKAGE_NUMBER")}</TableCell>
                <TableCell>{translate("SHIPMENT_NUMBER")}</TableCell>
                <TableCell>{translate("LAST_EVENT")}</TableCell>
                <TableCell>{translate("LAST_EVENT_TIME")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerOrderPackages.length === 0 ? (
                <TableRow sx={TABLE_ROW_STYLE}>
                  <TableCell component="th" scope="row" colSpan={4} sx={{ textAlign: "center" }}>
                    {translate("NO_ROWS")}
                  </TableCell>
                </TableRow>
              ) : (
                customerOrderPackages.map(row => (
                  <TableRow
                    key={row.id}
                    sx={TABLE_ROW_STYLE}
                    hover={true}
                    onClick={() => {
                      const path = generatePath(pages.CUSTOMER_ORDER_PACKAGE_DETAIL.PATH, {
                        customerOrderPackage: row.id,
                      });

                      history.push({
                        location: {
                          pathname: path,
                        },
                        breadcrumb: { text: translate("PACKAGE_OVERVIEW"), link: path },
                      });
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.sscc}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.shipmentNumber}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.lastEvent}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.lastEventTimeStamp ? dayjs(row.lastEventTimeStamp).format(DATE_FORMATS.YYYY_MM_DD) : ""}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default CustomerOrderPackages;
