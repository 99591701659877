import { IconButton, TextField } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { InputProps } from "./dataTypes";

const InputField = ({ customCssClass, showClearButton, onClear, ...rest }: InputProps) => {
  return (
    <TextField
      className={customCssClass}
      {...rest}
      InputProps={{
        ...rest.InputProps,
        endAdornment:
          rest.value != null && rest.value !== "" && showClearButton ? (
            <IconButton onClick={onClear}>
              <ClearIcon />
            </IconButton>
          ) : (
            rest.InputProps?.endAdornment
          ),
      }}
      sx={{
        ...(showClearButton
          ? {
              "& .MuiButtonBase-root": {
                opacity: 0,
                transition: "opacity 0.3s ease-in-out",
              },
              "&:hover .MuiButtonBase-root": {
                opacity: 1,
              },
            }
          : {}),
        '& input[type="search"]::-webkit-search-cancel-button': {
          opacity: 0,
        },
        '&:hover input[type="search"]::-webkit-search-cancel-button': {
          opacity: 0,
        },
        ...rest.sx,
      }}
    />
  );
};

export default InputField;
