import classNames from "classnames";
import { lazy } from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectIsInitialized, selectIsSingleSignOn, selectSessionId } from "../../features/session/sessionSelectors";
import FallbackSuspense from "../../utils/FallbackSuspense";
import IdleTimer from "../../utils/IdleTimer";
import pages from "../../utils/pages";
import useInit from "../../utils/useInit";
import { useMobile } from "../../utils/viewport";
import ComflowContent, { MYG_ACTIONS } from "../Comflow/ComflowContent";
import IsakRedirect from "../Comflow/IsakRedirect";
import Logout from "../Start/Logout";
import StartPage from "../Start/StartPage";
import CustomerOrderDetailPage from "../TrackAndTrace/CustomerOrderDetail/CustomerOrderDetailPage";
import CustomerOrderPackageDetailPage from "../TrackAndTrace/CustomerOrderPackageDetail/CustomerOrderPackageDetailPage";
import TrackAndTracePage from "../TrackAndTrace/TrackAndTracePage";
import ZetesContent from "../Zetes/ZetesContent";
import Breadcrumbs from "../shared/Breadcrumbs/Breadcrumbs";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import UserFeedback from "../shared/UserFeedback/UserFeedback";
import LazyRoute from "./LazyRoute/LazyRoute";
import styles from "./Site.module.scss";

const ShipmentDetailPage = lazy(() => import("../TrackAndTrace/ShipmentDetail/ShipmentDetail"));
const MobileHeader = lazy(() => import("./Header/MobileHeader/MobileHeader"));
const Header = lazy(() => import("./Header/Header"));
const Footer = lazy(() => import("./Footer/Footer"));
const MobileMenu = lazy(() => import("./MobileMenu/MobileMenu"));
const UserMessageToAcceptModal = lazy(() => import("../UserSettings/UserMessageToAcceptModal"));
const NewsWelcomeDetail = lazy(() => import("../Start/Welcome/NewsWelcomeDetail/NewsWelcomeDetail"));

const AdministrationMenu = lazy(() => import("../Administration/AdministrationMenu/AdministrationMenu"));
const SystemAdministrationMenu = lazy(
  () => import("../Administration/SystemAdministrationMenu/SystemAdministrationMenu"),
);

const MyPage = lazy(() => import("../MyPage/MyPage"));
const PurchaseOrderImportPage = lazy(() => import("../purchaseOrder/import/PurchaseOrderImportPage"));
const ReportsMenu = lazy(() => import("../Administration/ReportsMenu/ReportsMenu"));
const ResetPasswordPage = lazy(() => import("../Start/ResetPassword/ResetPasswordPage"));
const NotFoundPage = lazy(() => import("../NotFound/NotFoundPage"));
const UserSettingsPage = lazy(() => import("../UserSettings/UserSettingsPage"));
const FileUpload = lazy(() => import("../FileUpload/FileUploadPage"));

export default function Site() {
  useInit();

  const isSmallScreen = useMobile();
  const userLoggedIn = !!useAppSelector(selectSessionId);
  const isInitialized = useAppSelector(selectIsInitialized);

  if (!isInitialized) {
    return <LoadingSpinner />;
  }

  return (
    <Router>
      <IdleTimer />
      {userLoggedIn &&
        (isSmallScreen ? (
          <FallbackSuspense>
            <MobileHeader />
          </FallbackSuspense>
        ) : (
          <FallbackSuspense>
            <Header />
          </FallbackSuspense>
        ))}
      <main
        className={classNames(
          styles.main,
          userLoggedIn ? styles.loggedIn : styles.notLoggedIn,
          isSmallScreen && userLoggedIn && styles.loggedInMobile,
        )}
      >
        <Breadcrumbs />
        <PageRouter />
        <UserFeedback />
      </main>
      {userLoggedIn && (
        <FallbackSuspense>
          <Footer />
        </FallbackSuspense>
      )}
      {isSmallScreen && (
        <FallbackSuspense>
          <MobileMenu />
        </FallbackSuspense>
      )}
      {userLoggedIn && (
        <FallbackSuspense>
          <UserMessageToAcceptModal />
        </FallbackSuspense>
      )}
    </Router>
  );
}

const PageRouter = () => {
  const sessionId = useAppSelector(selectSessionId);
  const isSingleSignOn = useAppSelector(selectIsSingleSignOn);

  return (
    <>
      {!sessionId && !isSingleSignOn ? (
        <Switch>
          <Route exact path={pages.HOME}>
            <StartPage desiredPage={location.pathname + location.search} />
          </Route>
          <LazyRoute exact path={pages.RESET_PASSWORD.PATH}>
            <ResetPasswordPage />
          </LazyRoute>
          <Redirect to={pages.HOME} />
        </Switch>
      ) : (
        <Switch>
          <LazyRoute exact path={pages.RESET_PASSWORD.PATH}>
            <ResetPasswordPage />
          </LazyRoute>
          <Route exact path={pages.HOME}>
            <StartPage />
          </Route>
          <LazyRoute exact path={pages.LOGOUT.PATH}>
            <Logout />
          </LazyRoute>
          <LazyRoute exact path={pages.NEWS_DETAIL.PATH}>
            <NewsWelcomeDetail />
          </LazyRoute>
          <LazyRoute path={pages.USER_SETTINGS.PATH}>
            <UserSettingsPage />
          </LazyRoute>
          {Object.values(pages).map(page => {
            if (typeof page !== "object") return;

            if ("ISAK_ROUTE_ID" in page) {
              return (
                <Route exact path={page.PATH} key={page.PATH}>
                  <IsakRedirect redirectTo={page.ISAK_ROUTE_ID} mygAction={MYG_ACTIONS.SELECT_GOODS_OWNER} />
                </Route>
              );
            } else if ("COMFLOW_TASK_ID" in page) {
              return (
                <Route exact path={page.PATH} key={page.PATH}>
                  <ComflowContent startTask={page.COMFLOW_TASK_ID} mygAction={MYG_ACTIONS.SELECT_GOODS_OWNER} />
                </Route>
              );
            }

            return;
          })}
          <Route exact path={pages.ZETES_IMAGES.PATH} key={pages.ZETES_IMAGES.PATH}>
            <ZetesContent />
          </Route>
          <LazyRoute exact path={pages.ADMINISTRATION.PATH}>
            <AdministrationMenu />
          </LazyRoute>
          <LazyRoute exact path={pages.SYSTEM_ADMINISTRATION.PATH}>
            <SystemAdministrationMenu />
          </LazyRoute>
          <LazyRoute exact path={pages.REPORTS.PATH}>
            <ReportsMenu />
          </LazyRoute>
          <LazyRoute exact path={pages.FILE_UPLOAD.PATH}>
            <FileUpload />
          </LazyRoute>
          <LazyRoute path={pages.MY_PAGE.PATH}>
            <MyPage />
          </LazyRoute>
          <LazyRoute path={pages.PURCHASE_ORDER_IMPORT.PATH}>
            <PurchaseOrderImportPage />
          </LazyRoute>
          <LazyRoute path={pages.CUSTOMER_ORDER_DETAIL.PATH}>
            <CustomerOrderDetailPage />
          </LazyRoute>
          <LazyRoute path={pages.CUSTOMER_ORDER_PACKAGE_DETAIL.PATH}>
            <CustomerOrderPackageDetailPage />
          </LazyRoute>
          <LazyRoute path={pages.SHIPMENT_DETAIL.PATH}>
            <ShipmentDetailPage />
          </LazyRoute>
          <LazyRoute path={pages.TRACK_AND_TRACE.PATH}>
            <TrackAndTracePage />
          </LazyRoute>
          <LazyRoute>
            <NotFoundPage />
          </LazyRoute>
        </Switch>
      )}
    </>
  );
};
