import { createTheme } from "@mui/material";
import type {} from "@mui/x-data-grid-premium/themeAugmentation";

export const Default = createTheme({
  palette: { primary: { main: "#005d92" } },
  typography: {
    htmlFontSize: 10,
    allVariants: {
      fontFamily: "PostNordSans",
      textTransform: "none",
      fontSize: "1.4rem",
    },
    caption: {
      fontSize: "1.2rem",
    },
    h1: {
      fontSize: "3rem",
      fontWeight: "700",
      lineHeight: "1.1",
    },
    h3: {
      fontSize: "2.6rem",
      fontWeight: "700",
      lineHeight: "1.1",
    },
    h4: {
      fontSize: "2.4rem",
      fontWeight: "700",
    },
    h5: {
      fontWeight: "500",
    },
    h6: {
      fontWeight: "500",
      fontSize: "2rem",
    },
  },

  components: {
    MuiDataGrid: {
      styleOverrides: {
        filterFormOperatorInput: {
          width: "150px",
        },
        root: {
          "& .MuiInputLabel-root": {
            opacity: "0",
          },
          "& .MuiInputLabel-shrink": {
            opacity: "1",
            color: "#005d92",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            // fontSize: "2rem", //har kvar ifall det måste tas tillbak
          },
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { size: "small" },
          style: {
            width: "29rem",
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          input: {
            height: "4.2rem",
            boxSizing: "border-box",
          },
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: "small", // Set the default size to 'small' for all MuiSvgIcon components
      },
      variants: [
        {
          props: { fontSize: "small" },
          style: {
            fontSize: "2.4rem", // Adjust the size as needed
          },
        },
      ],
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          margin: "0px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          listStyleType: "disc",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          display: "list-item",
          wordWrap: "break-word",
          padding: "0",
        },
      },
    },
    // TODO - variant ska läggas till senare när vi vet hur(måste undersökas)
    // MuiButton: {
    //   variants: [
    //     {
    //       props: { variant: "rounded" },
    //       style: {
    //         textTransform: "none",
    //         backgroundColor: "#005d92",
    //         color: "white",
    //         borderRadius: "5rem",
    //         "&:hover": {
    //           backgroundColor: "rgb(0, 65, 102)",
    //           color: "white",
    //         },
    //       },
    //     },
    //   ],
    // },
  },
});
