import { Box, Typography } from "@mui/material";

//Möjligen lägga till olika varianter var label ska vara, nu är det alltid en top
const Label = ({ label, value }: { label: string; value?: string | number }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="subtitle1" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1" sx={{ minHeight: "2.1rem" }}>
        {value}
      </Typography>
    </Box>
  );
};

export default Label;
